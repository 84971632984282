.fc .fc-col-header-cell-cushion {
  color: #656b72;
  font-family: "Poppins";
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
}

.fc .fc-scrollgrid-sync-inner {
  background-color: #fafafa;
}
.fc .fc-timegrid-slot {
  font-family: Poppins;
  color: #333333;
  height: 45px;
}
.fc .fc-toolbar-chunk {
  display: flex;
}

.fc .fc-toolbar-title {
  font-family: Poppins;
  font-weight: 600;
  line-height: 32.02px;
  font-size: 24px;
  padding-top: 5px;
}

.fc-theme-standard .fc-scrollgrid,
.fc .fc-timegrid-axis {
  border: none;
}
.fc .fc-scrollgrid table {
  border-top-style: unset;
}

td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  border-left: none;
  border-bottom: none;
  border-top: none;
}

.fc .fc-timegrid-slot-label-cushion {
  font-weight: 500;
}

.fc .fc-prev-button,
.fc .fc-prev-button:hover,
.fc .fc-next-button,
.fc .fc-next-button:hover {
  background-color: #ffbb00;
  border-color: #ffbb00;
}
.fc .fc-prev-button:active,
.fc .fc-next-button:active {
  background-color: white;
  color: black;
  border-color: #ffbb00;
}
/* .fc .fc-addNewMeeting-button {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  color: #41a3fc;
  letter-spacing: 0.46px;
  line-height: 24px;
  background-color: white;
  border-color: #41a3fc;
  text-transform: uppercase;
}
.fc .fc-addNewMeeting-button:hover {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  color: #41a3fc;
  letter-spacing: 0.46px;
  line-height: 24px;
  background-color: white;
  border-color: #41a3fc;
  text-transform: uppercase;
} */
