body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/****** logo ******/
.logo {
  width: 150px;
  height: auto;
}

.mob-logo {
  width: 100px;
  height: auto;
}

/*  view list  */
.view-edit {
  float: right;
}
.blog-card {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.21);
  text-align: start;
}
.meetings-card {
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.19);
  text-align: start;
}
.meetings-card p {
  line-height: 0.7;
}
.author-name {
  font-size: 18px;
}

/* upload img btn */
input[type="file"]::file-selector-button {
  border: 1px solid #d3d2db;
  padding: 0.4em;
  border-radius: 10px;
  transition: 1s;
  color: #757579;
}
input[type="file"]::file-selector-button:hover {
  cursor: pointer;
}

/* *****Search input box******* */
.search-box {
  border: 1px solid #d3d2db;
  padding: 0.4em;
  border-radius: 10px;
  transition: 1s;
  color: #757579;
  outline: none;
}

/* input[type=text]:focus {
  background-color: #d3d2db;
} */

.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 150px;
  margin: 0;
  padding: 0;
  list-style: none;
  right: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 9;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}
li,
.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  padding: 15px;
  line-height: 22px;
  font-size: 15px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;

  transition: all 0.4s ease;
}
/* 
@media only screen and (max-width: 991px) {
  .menu-item-has-children .sub-menu {
    position: absolute;
    text-align: left;
    min-width: 210px;
    margin: 0;
    padding: 0;
    list-style: none;
    left: 0;
    top: 100%;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    z-index: 9;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 4px; }
    li,.menu-item-has-children .sub-menu li {
      display: block;
      margin-left: 0;
      padding: 15px;
      line-height: 22px;
      font-size: 15px;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
  
} */

/* Pagination active  */
.paginate-div {
  overflow-x: hidden;
}
.pagination {
  margin: 10px auto;
  display: flex;
  list-style: none;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 50px;
  background-color: #18d26e;
  color: #fff;
  padding: 5px 8px;
}
.pagination > .active > a {
  /* background-color: #47ccde ; */
  /* border-color: #47ccde ; */
  border-bottom: 2px solid #18d26e;
}

.pagination > li > a {
  /* margin-left:-1px; */
  cursor: pointer;
}

/* resume upload link */
.resume-link {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.backgroundRed {
  background-color: red;
}

.backgroundYellow {
  background-color: yellow;
}

/* caledar highlight color */
/* .highlight {
text-emphasis-color: #18d26e;
text-emphasis-style: dot;
  text-emphasis-position: under left;
  -webkit-text-emphasis-style: dot;
  -webkit-text-emphasis-position: under;
  font-weight: bolder;
} */
.highlight {
  text-decoration-line: underline;
  text-decoration-color: #18d26e;
  text-decoration-style: solid;
  text-decoration-thickness: 0.2em;
}

/* react calendar */

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 100%;
}
.react-calendar__tile--now {
  /* background: #ffff76; */

  border-radius: 100%;
  border-color: #fcbf00;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 100%;
  border-color: #fcbf00;
}
.react-calendar__tile--hasActive {
  /* background: #76baff; */

  border-radius: 100%;
  border-color: #fcbf00;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /* background: #a9d4ff; */
  border-radius: 100%;
  border-color: #fcbf00;
}
.react-calendar__tile--active {
  background: #fcbf00;
  border-radius: 100%;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  border-radius: 100%;
}

/* react super table */
/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}
.responsiveTable thead {
  background-color: #18d26e;
}
.responsiveTable th {
  border: 1px solid rgb(214, 211, 211);
  padding: 5px;
}
.responsiveTable td {
  border: 1px solid rgb(214, 211, 211);
  padding: 2px;
}
.responsiveTable td .tdBefore {
  display: none;
}
.table-select {
  width: 135px;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    /* border-bottom: 1px solid rgb(221, 217, 217); */
  }

  .responsiveTable tbody tr {
    border: 1px solid rgb(214, 211, 211);
    padding: 0.25em;
    margin: 5px;
    border-radius: 10px;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-bottom: 2px;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
  .table-select {
    display: block;
  }
}
